<template>
  <div>
    <DynamicForm
      ref="form"
      v-model="model"
      :rules="rules"
      :fieldData="fieldData"
      style="margin-top: 0.21333rem;"
    />
    <Card style="background-color: #F5F6FF;">
      <h3 slot="title">计算结果</h3>
      <CardCell label="首付金额" :value="downPaymentAmount" justify="space-between" />
      <CardCell label="尾款金额" :value="balancePaymentAmount" justify="space-between" />
      <CardCell label="贷款金额" :value="loanPaymentAmount" justify="space-between" />
      <CardCell label="贷款期数" :value="model.nper" justify="space-between" />
      <CardCell label="月供金额" :value="monthPaymentAmount" justify="space-between" />
    </Card>
  </div>
</template>
<script>
import { getLoan, getDetail, getCarseries, getCarType } from '@/api/order'
import DynamicForm from '_c/dynamicForm'
import Card from '_c/card'
import CardCell from '_c/card/cardCell'
export default {
  name: 'financialOrder',
  components: {
    DynamicForm,
    Card,
    CardCell
  },
  props: {
    orderNum: {
      type: String,
      default: ''
    },
    isPure: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      model: {
        fiCode: '',
        fiOrgCode: '',
        cityId: '',
        downPaymentRate: '',
        balancePaymentRate: '',
        nper: '',
        theLenderType: '',
        theLender: '',
        theLenderCertificate: '',
        theLenderPhone: '',
        enterpriseName: '',
        enterpriseCode: '',
        carSeriesCode: '',
        carTypeCode: ''
      },
      rules: {
        carSeriesCode: [
          { required: true, message: '请选择工程车型' }
        ],
        carTypeCode: [
          { required: true, message: '请选择基础车型' }
        ],
        fiCode: [
          { required: true, message: '请选择金融方案' }
        ],
        fiOrgCode: [
          { required: true, message: '请选择贷款银行' }
        ],
        cityId: [
          { required: true, message: '请选择贷款城市' }
        ],
        downPaymentRate: [
          { required: true, message: '请选择首付比例' }
        ],
        balancePaymentRate: [
          { required: true, message: '请选择尾款比例' }
        ],
        nper: [
          { required: true, message: '请选择贷款期数' }
        ],
        theLenderType: [
          { required: true, message: '请选择贷款人类型' }
        ],
        theLender: [
          { required: true, message: '请填写贷款人' }
        ],
        theLenderCertificate: [
          { required: true, message: '请填写贷款人证件号' },
          { pattern: this.$reg.cardNo.reg, message: this.$reg.cardNo.msg }
        ],
        theLenderPhone: [
          { required: true, message: '请填写贷款人手机号' },
          { pattern: this.$reg.mobile.reg, message: this.$reg.mobile.msg }
        ],
        enterpriseName: [
          { required: true, message: '请填写企业名称' }
        ],
        enterpriseCode: [
          { required: true, message: '请填写企业代码' },
          { pattern: this.$reg.cardNo.reg, message: this.$reg.cardNo.msg }
        ]
      },
      orderInfo: {},
      sourceData: [],
      financeList: [],
      bankList: [],
      cityList: [],
      downPaymentRateList: [],
      balancePaymentRateList: [],
      carSeriesList: [],
      carTypeList: [],
      fiList: [],
      theLenderTypeList: [
        { value: '0', label: '个人' },
        { value: '1', label: '企业' }
      ],
      financeCodeMap: {
        '1': '提前贷',
        '3': '分期贷',
        '5': '极速贷'
      }
    }
  },
  computed: {
    fieldData() {
      return [
        {
          data: [
            { label: '工程车型', key: 'carSeriesCode', type: 'select', options: this.carSeriesList, visible: this.isPure },
            { label: '基础车型', key: 'carTypeCode', type: 'select', options: this.carTypeList, visible: this.isPure },
            { label: '金融方案', key: 'fiCode', type: 'select', options: this.financeList },
            { label: '贷款银行', key: 'fiOrgCode', type: 'select', options: this.bankList },
            { label: '贷款城市', key: 'cityId', type: 'select', options: this.cityList },
            { label: '首付比例', key: 'downPaymentRate', type: 'select', options: this.downPaymentRateList },
            { label: '尾款比例', key: 'balancePaymentRate', type: 'select', options: this.balancePaymentRateList },
            { label: '贷款期数', key: 'nper', props: { disabled: true } },
            { label: '贷款人类型', key: 'theLenderType', type: 'select', options: this.theLenderTypeList, visible: !this.isPure },
            { label: '贷款人', key: 'theLender', props: { maxLength: 20 }, visible: !this.isPure && this.model.theLenderType === '0' },
            { label: '贷款人证件号', key: 'theLenderCertificate', props: { maxLength: 20 }, visible: !this.isPure && this.model.theLenderType === '0' },
            { label: '企业名称', key: 'enterpriseName', props: { maxLength: 30 }, visible: !this.isPure && this.model.theLenderType === '1' },
            { label: '企业代码', key: 'enterpriseCode', props: { maxLength: 20 }, visible: !this.isPure && this.model.theLenderType === '1' },
            { label: '贷款人手机号', key: 'theLenderPhone', props: { maxLength: 11 }, visible: !this.isPure }
          ]
        }
      ]
    },
    invoicePrice() {
      if (!this.isPure) return this.orderInfo.invoicePrice
      const carType = this.carTypeList.find(item => item.value === this.model.carTypeCode) || {}
      return parseFloat(carType.price)
    },
    monthPaymentAmount() { // 月供金额
      return ((this.loanPaymentAmount * (1 + (this.financialPlanRate * this.model.nper / 12)) / this.model.nper) || 0).toFixed(2)
    },
    loanPaymentAmount() { // 贷款金额
      const { downPaymentRate, balancePaymentRate } = this.model
      return ((this.invoicePrice * (1 - downPaymentRate - balancePaymentRate)) || 0).toFixed(2)
    },
    loanPaymentRate() { // 贷款比例
      return ((this.loanPaymentAmount / this.invoicePrice) || 0).toFixed(2)
    },
    downPaymentAmount() { // 首付金额
      return ((this.model.downPaymentRate * this.invoicePrice) || 0).toFixed(2)
    },
    balancePaymentAmount() { // 尾款金额
      return ((this.model.balancePaymentRate * this.invoicePrice) || 0).toFixed(2)
    },
    financialPlanRate() { // 金融方案费率
      if (this.model.downPaymentRate) {
        return this.fiList.find(item => this.model.downPaymentRate === parseFloat(item.downPaymentRate)).financialPlanRate
      } else if (this.model.balancePaymentRate) {
        return this.fiList.find(item => this.model.balancePaymentRate === parseFloat(item.balancePaymentRate)).financialPlanRate
      }
      return 0
    }
  },
  watch: {
    'model.fiCode'(value) {
      this.onLoanChange(value)
    },
    'model.fiOrgCode'() {
      this.onLoanChange(this.model.fiCode)
    },
    'model.cityId'() {
      this.onLoanChange(this.model.fiCode)
    },
    'model.carTypeCode'() {
      this.$requestHandler(this.getLoan.bind(this))
    },
    'model.downPaymentRate'() {
      const target = this.fiList.find(item => parseFloat(item.downPaymentRate) === this.model.downPaymentRate)
      if (this.model.balancePaymentRate !== parseFloat(target.balancePaymentRate)) {
        this.model.balancePaymentRate = parseFloat(target.balancePaymentRate)
      }
      this.model.nper = target.nper
    },
    'model.balancePaymentRate'() {
      const target = this.fiList.find(item => parseFloat(item.balancePaymentRate) === this.model.balancePaymentRate)
      if (this.model.downPaymentRate !== parseFloat(target.downPaymentRate)) {
        this.model.downPaymentRate = parseFloat(target.downPaymentRate)
      }
      this.model.nper = target.nper
    }
  },
  methods: {
    init() {
      if (this.isPure) {
        return this.getCarseries()
          .then(this.getCarType)
      } else {
        return this.getOrderDetail()
          .then(this.getLoan)
      }
    },
    validate() {
      return this.$refs.form.validate().then(valid => {
        if (valid) {
          const data = this.model
          return {
            ...data,
            downPaymentAmount: this.downPaymentAmount,
            balancePaymentAmount: this.balancePaymentAmount,
            loanPaymentRate: this.loanPaymentRate,
            loanPaymentAmount: this.loanPaymentAmount,
            monthPaymentAmount: this.monthPaymentAmount
          }
        }
        return false
      })
    },
    onLoanChange (value) {
      let fiList = []
      const cityList = []
      const bankList = []
      const initialRatio = []
      const finalRatio = []

      if (!this.sourceData.length) return

      this.sourceData.forEach(item => {
        if (item.fiCode === value) {
          // 银行数据
          if (!bankList.find(c => c.value === item.fiOrgCode)) {
            bankList.push({
              value: item.fiOrgCode,
              label: item.fiOrgName
            })
          }

          // 城市数据
          if (this.model.fiOrgCode !== '') {
            if (item.fiOrgCode === this.model.fiOrgCode) {
              item.cityList.forEach(city => {
                if (!cityList.find(c => c.label === `${city.provinceName || ''} ${city.cityName || ''}`)) {
                  cityList.push({
                    value: city.cityId,
                    label: `${city.provinceName || ''} ${city.cityName || ''}`
                  })
                }
              })
            }
          }

          if (this.model.cityId !== '') {
            const targetCity = this.cityList.find(city => city.value === this.model.cityId)
            if (targetCity && item.cityList.find(city => targetCity.label === `${city.provinceName || ''} ${city.cityName || ''}`)) {
              item.fiList.forEach(fi => {
                // 首付比例
                if (!initialRatio.find(c => c.value === parseFloat(fi.downPaymentRate))) {
                  initialRatio.push({
                    value: parseFloat(fi.downPaymentRate),
                    label: `${Math.round(fi.downPaymentRate * 100)}%`
                  })
                }

                // 尾款比例
                if (!finalRatio.find(c => c.value === parseFloat(fi.balancePaymentRate))) {
                  finalRatio.push({
                    value: parseFloat(fi.balancePaymentRate),
                    label: `${Math.round(fi.balancePaymentRate * 100)}%`
                  })
                }
              })
            }
          }

          fiList = [...fiList, ...item.fiList]
        }
      })

      this.fiList = fiList
      this.bankList = bankList
      this.cityList = cityList
      this.downPaymentRateList = initialRatio.sort((a, b) => a.value - b.value)
      this.balancePaymentRateList = finalRatio.sort((a, b) => a.value - b.value)

      // 默认取第一个值
      if (this.bankList.length && !this.bankList.find(item => item.value === this.model.fiOrgCode)) {
        this.model.fiOrgCode = this.bankList[0].value
      }
      if (this.cityList.length && !this.cityList.find(item => item.value === this.model.cityId)) {
        this.model.cityId = this.cityList[0].value
      }
      if (this.downPaymentRateList.length && !this.downPaymentRateList.find(item => item.value === this.model.downPaymentRate)) {
        this.model.downPaymentRate = this.downPaymentRateList[0].value
      }
    },
    getOrderDetail() {
      const params = {
        orderNum: this.orderNum,
        channel: this.$storage.get('channel')
      }
      return getDetail(params).then(res => {
        if (res.success) {
          const data = res.data
          this.orderInfo = data
          Object.assign(this.model, {
            theLender: data.ownerName,
            theLenderPhone: data.ownerPhone,
            theLenderType: data.ownerType === '00' ? '0' : '1',
            theLenderCertificate: data.ownerCardNo,
            enterpriseName: data.enterpriseName,
            enterpriseCode: data.enterpriseCode
          })
        }
      })
    },
    getLoan() {
      const params = {
        channel: this.$storage.get('channel'),
        carSeriesCode: this.isPure ? this.model.carSeriesCode : this.orderInfo.carSeriesCode,
        carTypeCode: this.isPure ? this.model.carTypeCode : this.orderInfo.carTypeCode
      }
      return getLoan(params).then(res => {
        if (res.success) {
          const obj = {}
          const arr = []
          // 去重
          res.data.forEach(item => {
            obj[item.fiCode] = item.fiChallenged
          })
          for (const key in obj) {
            arr.push({
              value: key,
              label: obj[key]
            })
          }
          this.sourceData = res.data
          this.financeList = arr
          this.model.fiCode = this.financeList[0].value
        }
      })
    },
    // 获取工程车型数据
    getCarseries() {
      const params = {
        channel: this.$storage.get('channel')
      }
      return getCarseries(params).then(res => {
        if (res.success) {
          this.carSeriesList = res.data.map(item => {
            return {
              label: item.carSeriesCn,
              value: item.carSeriesCode
            }
          })
          this.model.carSeriesCode = this.carSeriesList[0].value
        }
      })
    },
    // 获取基础车型数据
    getCarType() {
      const params = {
        channel: this.$storage.get('channel'),
        carSeriesCode: this.model.carSeriesCode
      }
      return getCarType(params).then(res => {
        if (res.success) {
          this.carTypeList = res.data.map(item => {
            return {
              label: item.carTypeCn,
              value: item.carTypeCode,
              price: item.price
            }
          })
          this.model.carTypeCode = this.carTypeList[0].value
        }
      })
    }
  }
}
</script>