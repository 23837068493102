<template>
  <App title="金融计算器"
       left-arrow
       @click-left="$page.close()">
    <FinancialOrder ref="financialOrder"
                    :isPure="true" />
  </App>
</template>
<script>
import FinancialOrder from '_c/business/financialOrder'
export default {
  name: 'financialCalculator',
  components: {
    FinancialOrder
  },
  mounted () {
    this.$refs.financialOrder.init()
  }
}
</script>
<style lang="less" scoped>
/deep/ .van-field__control:disabled {
  padding-right: 25px;
}
</style>
